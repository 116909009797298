<template>
	<el-dialog
	    width="1000px"
		@open="open"
	  >
		<div class="a">
			<div class="a-title">入库</div>
			<div class="a-content">
				<el-radio-group v-model="activeNav">
				    <el-radio 
						:label="item.type" 
						v-for="item in stockType"
						:key="item.type">
							{{item.title}}
					</el-radio>
				</el-radio-group>
				<div v-show="activeNav === 0">
					<div class="a-row">
						<div class="a-row-span">选择商品:</div>
						<lida-select 
							@moreClick="showMoreGoods"
							@changeGoods="changeGoods"
							:list="list"
							:ids="ids"
							:label="`item.productName + '(' + item.barCode + ')'`"
						/>
					</div>
					<lida-table
						:height="300"
						:data="data"
						border
						:pagination="false"
						ref="table">
							<lida-table-column label="产品信息" fixed>
								<template #default="scope">
									<div class="g-info">
										<div class="g-icon">
											<img :src="scope.row.src"/>
										</div>
										<div class="g-right">
											<div class="g-r-title">
												{{scope.row.productName}}
												<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
											</div>
											<div class="g-r-data">
												{{scope.row.barCode || '无'}}
												<span>|</span>
												{{scope.row.seriesName || '无'}}
												<span>|</span>
												{{scope.row.skuNumber || '无'}}
											</div>
										</div>
									</div>
								</template>
							</lida-table-column>
							<lida-table-column label="订货号" prop="articleNo" />
							<lida-table-column label="入库数量" prop="qty">
								<template #default="scope">
									<el-input-number v-model="scope.row.qty" :min="0" />
								</template>
							</lida-table-column>
							<lida-table-column label="操作" fixed="right">
								<template #default="scope">
									<el-button size="mini" @click="deleteRow(scope)" type="text">移除</el-button>
								</template>
							</lida-table-column>
					</lida-table>
					<div class="a-row">
						<div class="a-row-span">入库备注:</div>
						<el-input
						    v-model="remarks"
						    :rows="3"
						    type="textarea"
						    placeholder=""
						/>
					</div>
				</div>
				<div v-show="activeNav === 1">
					<div class="a-row">
						<div class="a-row-span">选择收货单:</div>
						<el-select v-model="activeOrder" placeholder="请选择" style="width: 300px;">
						    <el-option
								v-for="item in orderList"
								:key="item.id"
								:label="item.courierCompany + '('+ item.courierNumber +')'"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</div>
					<lida-table
						:height="300"
						:data="orderData"
						border
						:pagination="false"
						ref="table">
							<lida-table-column label="产品信息" fixed>
								<template #default="scope">
									<div class="g-info">
										<div class="g-icon">
											<img :src="scope.row.src"/>
										</div>
										<div class="g-right">
											<div class="g-r-title">
												{{scope.row.productName}}
												<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
											</div>
											<div class="g-r-data">
												{{scope.row.barCode || '无'}}
												<span>|</span>
												{{scope.row.seriesName || '无'}}
												<span>|</span>
												{{scope.row.skuNumber || '无'}}
											</div>
										</div>
									</div>
								</template>
							</lida-table-column>
							<lida-table-column label="订货号" prop="articleNo" />
							<lida-table-column label="采购数量" prop="purchaseQty" />
							<lida-table-column label="已发货数量" prop="shipQty" />
							<lida-table-column label="入库数量" prop="qty" />
					</lida-table>
					<div class="a-row">
						<div class="a-row-span">入库备注:</div>
						<el-input
						    v-model="remarks1"
						    :rows="3"
						    type="textarea"
						    placeholder=""
						/>
					</div>
				</div>
			</div>
			<div class="a-footer">
				<el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
				<el-button style="margin-left: 20px;" size="mini" type="primary" @click="confirm">确认</el-button>
			</div>
		</div>
		<add-goods-dialog
			@success="addSuccess"
			:ids="ids"
			v-model="showGoods" />
	</el-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex'
	import addGoodsDialog from './addGoods.vue'
	import { ElMessage } from 'element-plus'
	export default{
		data(){
			return{
				activeNav:0,
				showGoods:false,
				data:[],  //商品列表数据
				list:[],  //获取列表
				ids:[],
				remarks:'',
				remarks1:'',
				orderList:[],  //收货单列表
				activeOrder:'',  //当前选中的订单id
				orderData:[], //收货单商品
			}
		},
		components:{
			addGoodsDialog
		},
		computed:{
			...mapState('stockModule',[
				'stockType'
			]),
		},
		methods:{
			...mapActions('stockModule',[
				'stockInStock',  //入库
				'stockListShipByBuyerId',  //收货单列表
				'stockListByShipId',  //查询收货单明细
			]),
			...mapActions('purchaseMudule',[
				'skuPage',  //获取商品列表
			]),
			close(){ //关闭弹框
				this.$emit('update:modelValue', false);
			},
			showMoreGoods(){ //更多的点击事件
				this.showGoods = true;
			},
			addSuccess(data){
				var arr = JSON.parse(JSON.stringify(this.data));
				data.map(item => {
					if(arr.some(v => v.id === item.id)){
						item.qty += arr.findIndex(v => v.id === item.id).qty; //合并数量
					}else{
						arr.push(item);
					}
				})
				this.data = arr;
			},
			getList(){ //获取商品列表
				this.skuPage({
					pageNo:1,
					pageSize:100,
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						data.rows.map(item => {
							item.qty = 1;
						})
						this.list = data.rows;
					}
				})
			},
			deleteRow(obj){ //删除行
				var data = JSON.parse(JSON.stringify(this.data));
				data.splice(obj.$index,1);
				this.data = [...data];
			},
			confirm(){ //确认的点击事件
				var data = this.activeNav === 0 ? JSON.parse(JSON.stringify(this.data)) :
				JSON.parse(JSON.stringify(this.orderData)),
				info = {
					itemList:[],
					remarks: this.activeNav === 0 ? this.remarks : this.remarks1,
					type:this.activeNav,
				};
				data.map(item => {
					info.itemList.push({
						qty:item.qty,
						skuId:item.id,
					})
				})
				if(this.activeNav === 1){
					if(this.activeOrder){
						info.purchaseShipId = this.activeOrder;
						info.purchaseId = this.orderList.filter(v => v.id === this.activeOrder)[0].documentId;
					}else{
						return ElMessage({
							type:'warning',
							message:'请选择需要入库的收货单'
						})
					}
				}
				if(!info.itemList.length){
					return ElMessage({
						type:'warning',
						message:'请选择入库商品'
					})
				}
				this.stockInStock(info).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						ElMessage({
							type:'success',
							message:'入库成功',
						})
						this.close();
						this.$emit('success');
					}
				})
			},
			getOrder(){ //获取收货单列表
				this.stockListShipByBuyerId({}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						this.orderList = data;
					}
				})
			},
			changeGoods(v,goods){
				this.data = this.data.filter(item => v.some(son => item.id === son));
				this.addSuccess(goods);
			},
			open(){ //清空数据
				this.activeNav = 0;
				this.showGoods = false;
				this.data = this.ids = this.list = this.orderList = this.orderData = [];
				this.remarks = this.remarks1 = this.activeOrder = '';
				this.getList();
				this.getOrder();
			}
		},
		created(){
			
		},
		watch:{
			data(v){
				var ids = [];
				v.map(item => {
					ids.push(item.id);
				})
				this.ids = ids;
			},
			activeOrder(v){
				if(v){
					this.stockListByShipId(v).then(res => {
						let {code,data} = res.data;
						if(code === '0'){
							data.map(item => {
								item.qty = item.shipQty;
								item.id = item.skuId;
							})
							this.orderData = data;
						}
					})
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.a{
		font-size: 14px;
		padding: 20px;
		.a-title{
			font-weight: bold;
			padding: 0 20px 20px 0;
		}
		.a-footer{
			display: flex;
			justify-content: center;
			padding-top: 20px;
		}
		.a-row{
			display: flex;
			align-items: center;
			padding: 10px 0;
			.a-row-span{
				white-space: nowrap;
				padding-right: 20px;
			}
		}
	}
	
</style>
