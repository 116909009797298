<template>
    <div class="number-box">
        <span>{{context}}：<span class="num">{{number}}</span>个</span>
    </div>
</template>
<script>
/**
 * @Data 2021-11-16
 * @Desc 表格头部的总数 
 */
export default {
    props: {
      context: {
          type: String,
          default: '总询价单数'
      },
      number: {
          type: [String, Number],
          default: 0
      }
    }
}
</script>
<style lang="less" scoped>
.number-box{
    font-size: 13px;
    color: #333333;
    .num{
        color: @highlight-purple;
    }
}
</style>


