<template>
	<div class="st" style="padding-bottom: 10px;">
		<div class="st-left">
			<slot></slot>
		</div>
		<div class="st-right">
			<el-date-picker v-model="time" type="daterange" range-separator="至" start-placeholder="开始时间"
				end-placeholder="结束时间" style="width: 350px !important;"
				value-format="YYYY-MM-DD"
				@change="handleChange">
			</el-date-picker>
			<div style="margin-left: 20px">
				<el-input v-model="input3" placeholder="关键字搜索" class="input-with-select" @change="handleChange">
					<template #append>
						<i class="iconfont icon-sousuo"></i>
					</template>
				</el-input>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				checks: [{
						name: "系列单",
						key: "key1"
					},
					{
						name: "品牌单",
						key: "key2"
					},
					{
						name: "产品单",
						key: "key3"
					},
				],
				checkList: [], //当前选中的数组
				time: [],
				input3: ''
			}
		},
		components: {

		},
		methods: {
			handleChange(){
				let obj = {}
				if(this.time.length > 0){
					obj.endDate = this.time[1]
					obj.startDate = this.time[0]
				}
				if(this.input3) obj.wd = this.input3
				this.$emit('commands', obj)
			}
		}

	}
</script>

<style scoped lang="less">
	.st {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.st-left {
			display: flex;
			align-items: center;
		}

		.st-right {
			display: flex;
			align-items: center;
		}
	}
</style>
