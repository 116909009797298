<template>
	<div>
		<search-table @commands="handleScreen">
			<template v-slot>
				<el-button
					type="primary"
					@click="addStock"
					size="mini">入库</el-button>
			</template>
		</search-table>
		<div class="info">
			<Sum-number context="总入库单数" :number="total"></Sum-number>
			<div style="margin-top: 10px;">
				<lida-table
					pagination
					:cutHeight="0"
					:data="data"
					:pageSize="pageSize"
					:page="page"
					border
					@change="pageChange"
					:total="total"
					ref="table">
						<lida-table-column 
							v-for="item in deploy"
							:label="item.title" 
							:prop="item.key" 
							:key="item.key"
							fixed/>
						<lida-table-column label="操作" fixed="right">
							<template #default="scope">
								<div @click="seeDetails(scope.row)" style="color: #D84146;cursor: pointer;">查看</div>
								<!-- <el-button size="mini" @click="seeDetails(scope.row)" type="text">查看</el-button> -->
							</template>
						</lida-table-column>
				</lida-table>
			</div>
		</div>
	</div>
	<add-stock-dialog
		v-model="showAddStock"
		@success="addSuccess"
	/>
</template>

<script>
	import SumNumber from '@/components/publicComponent/sumNumber.vue'
	import searchTable from '@/components/publicComponent/searchTable/index.vue'
	import addStockDialog from '../components/dialog/addStock.vue'
	import { mapActions, mapState } from 'vuex'
	export default{
		data(){
			return{
				total:0,
				page:1,
				pageSize:10,
				data:[],
				deploy:[
					{title:'入库时间',key:'time',fixed:true},
					{title:'入库类型',key:'type'},
					{title:'入库单号',key:'number'},
					{title:'关联采购单',key:'purchaseNumber'},
					{title:'备注',key:'remarks'}
				],
				showAddStock:false, //添加库存的隐藏显示
			}
		},
		components:{
			SumNumber,
			searchTable,
			addStockDialog
		},
		computed:{
			...mapState('stockModule',[
				'stockType',  //入库类型
			])
		},
		methods:{
			...mapActions('stockModule',[
				'stockPageIn',  //获取入库明细列表
			]),
			getList(){ //获取列表数据
				this.stockPageIn({
					pageSize:this.pageSize,
					pageNo:this.page
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						data.rows.map(item => {
							item.type = this.getText('stockType',item.type);
						})
						this.data = data.rows;
						this.total = data.totalCount;
					}
				})
			},
			pageChange(page,pageSize){
				this.page = page;
				this.pageSize = pageSize;
				this.getList();
			},
			getText(type,val){
				return this[type].some(v => v.type === val || v.type === Number(val)) ?
				this[type].filter(v => v.type === val || v.type === Number(val))[0].title : '';
			},
			seeDetails(row){ //查看入库明细详情
				this.$router.push({
					path:'/stock/enterStockDetails',
					query:{
						id:row.id
					}
				})
			},
			addSuccess(){ //入库成功刷新列表
				this.getList();
			},
			addStock(){ //添加库存
				this.showAddStock = true;
			},
		},
		created(){
			this.getList();
		}
	}
</script>

<style scoped lang="less">
	
</style>
